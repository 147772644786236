/*
	DESCRIPTION / USAGE:
		Global CSS file

	TODO:

*/

/* Import Tailwind */
@tailwind components;
@tailwind utilities;

.MuiCard-root {
	background-image: none !important;
}

.flip_horizontal {
	transform: scaleX(-1)
}

.MuiInputLabel-formControl {
	z-index: 0;
}

.ql-snow .ql-stroke {
	stroke: #fff
}

.ql-snow .ql-picker {
	color: #fff
}

.ql-snow .ql-picker-options {
	background: #272b33;
}

.ql-editor{
    min-height:300px;
}

.ql-scroll-html-render h1,
.ql-scroll-html-render h2,
.ql-scroll-html-render h3,
.ql-scroll-html-render p,
.ql-scroll-html-render ol,
.ql-scroll-html-render ul
{
	margin-top: 0px;
	margin-bottom: 0px;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&family=Pirata+One&display=swap');

.font-pirata-one {
	font-family: 'Pirata One', sans-serif;
}